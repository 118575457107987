<template>
  <div class="container--fluid pa-0">
    <the-header />
    <div class="container--fluid pa-0">
      <v-card flat color="primary" height="300" class="d-flex">
        <v-card
          flat
          color="secondary"
          height="89"
          class="border-r-0 ma-auto d-flex align-center justify-center px-10 py-4"
        >
          <p
            :class="$vuetify.breakpoint.xsOnly ? 'font-30' : 'font-40'"
            class="mb-0 line-h-62 font-weight--bold white--text letter-s-25 font-sofia"
          >
            Search Results
          </p>
        </v-card>
      </v-card>
      <scrollactive class="h-menu" :offset="130">
        <a href="#search-matches" class="scrollactive-item"> Search Matches </a>
        <a href="#clubs" class="scrollactive-item">Clubs</a>
        <a href="#faq" class="scrollactive-item">FAQs</a>
      </scrollactive>
      <!-- <div class="h-menu">
        <ul>
          <li>
            <a class="active" href="#search-matches">Search Matches</a>
          </li>
          <li><a href="#clubs">Clubs</a></li>
          <li><a href="#faq">FAQs</a></li>
        </ul>
      </div> -->
      <v-divider class="line"></v-divider>
      <div class="container--fluid pt-0" id="search-matches">
        <div class="manchester-team container pa-0">
          <v-row class="ma-0">
            <v-col cols="12" md="4" class="filter-and-sellticket">
              <filter-results type="search" :id="1" />
              <sell-your-ticket-card />
            </v-col>
            <v-col cols="12" md="8" class="pl-15 upcoming-team-matches">
              <upcoming-fixtures-by-filter
                title="Upcoming Matches"
                :loading="false"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="container--fluid bg-grey-light" id="tournament-info">
        <the-features />
      </div>
      <div class="container" id="clubs">
        <the-premier-league />
      </div>
      <div class="container" id="faq">
        <sell-your-ticket />
        <premier-club-info />
      </div>
    </div>
    <the-footer />
  </div>
</template>
<script>
import TheHeader from "../components/TheHeader.vue";
import UpcomingFixturesByFilter from "@/components/UpcomingFixturesByFilter.vue";
import FilterResults from "@/components/FilterResults.vue";
import SellYourTicketCard from "@/components/SellYourTicketCard.vue";
import TheFeatures from "../components/TheFeatures.vue";
import ThePremierLeague from "../components/ThePremierLeague.vue";
import SellYourTicket from "../components/SellYourTicket.vue";
import PremierClubInfo from "../components/PremierClubInfo.vue";
import TheFooter from "../components/TheFooter.vue";

export default {
  name: "Search",
  components: {
    TheHeader,
    UpcomingFixturesByFilter,
    FilterResults,
    SellYourTicketCard,
    TheFeatures,
    ThePremierLeague,
    SellYourTicket,
    PremierClubInfo,
    TheFooter,
  },
};
</script>
<style></style>
